import React from 'react'
import axios from 'axios'
import GalleryModal from '../gallery_modal'

export default class GalleryModalListener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: null,
      activeImageId: null,
      imageIdArray: [],
      dataLoaded: false,
      galleryDescription: null,
      buttonContents: this.props.buttonContents,
    };
  }

  componentDidMount() {
    window.addEventListener('bb.open-gallery', this.updateModal);
  }

  componentWillUnmount() {
    window.removeEventListener('bb.open-gallery', this.updateModal);
  }

  openModal = () => {
    if( this.galleryModal ){
      this.galleryModal.openModal();
    }
  }

  updateModal = (e) => {
    if( !!e && !!e.detail && !!e.detail.gallery_id ){
      const { gallery_id, image_id } = e.detail;
      
      if( gallery_id === this.state.galleryId ){
        this.setState({ activeImageId: image_id || null }, this.openModal)
      } else {
        axios.get(`/api/public/collections/${gallery_id}.json`).then(res => {
          if( res.status === 200 ){
            this.setState({
              galleryUrl: res.data.gallery_url,
              imageIdArray: res.data.image_ids,
              galleryName: res.data.name,
              galleryLocation: res.data.location,
              galleryDescription: res.data.description,
              galleryTeamName: res.data.team_name,
              dataLoaded: true,
              activeImageId: image_id,
            }, () => this.openModal() )
          }
        });
      }
    }
  }

  render() {
    const { galleryUrl, imageIdArray, galleryName, galleryLocation, activeImageId, galleryDescription, galleryTeamName } = this.state;
    return (
      <div className="react-tlc gallery-modal-listener">
        <GalleryModal
          ref={ ref => this.galleryModal = ref }
          galleryUrl={galleryUrl}
          imageIds={imageIdArray.join(",")}
          galleryName={galleryName}
          galleryLocation={galleryLocation}
          activeImageId={activeImageId}
          galleryDescription={galleryDescription}
          galleryTeamName={galleryTeamName}
          showCTA={true}
        ></GalleryModal>
      </div>
    );
  }
}