import React from 'react'
import ReactDOM from 'react-dom'

import GalleryModalListener from '../components/gallery_modal_listener'


document.addEventListener((typeof Turbolinks === 'undefined' ? 'DOMContentLoaded' : 'turbolinks:load'), () => {
  const container = document.getElementById('gallery-modal-listener');
  ReactDOM.render( <GalleryModalListener />, container )
})
